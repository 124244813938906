<script setup lang="ts">
import { LeaseVariationKind, type ILeaseVariation } from '@register'
import { formatActionTitle } from './utils'

const props = defineProps<{
  variation: ILeaseVariation
}>()

const actionTitle = formatActionTitle(props.variation)
const kind = computed(() => props.variation.variationKind)

const { visible: isSaving } = useRequestCounter('variation')
</script>

<template>
  <div class="flex items-center justify-between px-4">
    <div class="space-x-0">
      <span
        class="font-semibold text-gray-100"
        :class="{
          'text-primary': kind === LeaseVariationKind.STATUS_CHANGED,
          'text-success': kind === LeaseVariationKind.ADDED,
          'text-error': kind === LeaseVariationKind.REMOVED,
          'text-warning': kind === LeaseVariationKind.CHANGED,
        }"
      >
        {{ actionTitle }}
      </span>
      <span v-if="variation.leaseField"> for the field </span>
      <span v-if="variation.leaseField" class="font-semibold text-gray-100">
        {{ title(variation.leaseField?.id) }}</span
      >
    </div>

    <div v-if="isSaving" class="flex items-center text-sm text-gray-500">
      <Spinner class="fill-gray-500" size="xs" />
      Saving...
    </div>
  </div>
</template>
