import { LeaseVariationKind, type ILeaseVariation } from '@register'

export const formatActionTitle = (variation: ILeaseVariation) => {
  let kind
  switch (variation.variationKind) {
    case LeaseVariationKind.ADDED:
      kind = 'Adding'
      break
    case LeaseVariationKind.REMOVED:
      kind = 'Removing'
      break
    case LeaseVariationKind.CHANGED:
      kind = 'Changing'
      break
    case LeaseVariationKind.STATUS_CHANGED:
      kind = 'Status changed'
      break
  }

  if (!variation.leaseField) {
    return kind
  }

  return `${kind} ${variation?.leaseField?.fieldType}`
}
